import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";
import {Client} from '../api/client';

@inject(DialogController, Client)
export class ProductChoice
{
    constructor(dialogController, client)
    {
        this.dialogController = dialogController;
        this.client = client;
    }

    activate(context)
    {
        this.data = context.data;
        this.title = context.title;
        this.order = context.order;
    }

    submit(object)
    {
        this.dialogController.ok(object);

        return Promise.resolve();
    }
}
