import {bindable, containerless, customElement} from 'aurelia-framework';

@containerless()
@customElement('order-payment-matrix')
export class OrderPaymentMatrix {

    @bindable order;

    headers;
    rows;
    table;
    sums;

    bind() {
        if (!this.order || !this.order.paymentMatrix) {
            return;
        }

        const m = this.order.paymentMatrix;
        const types = Object.getOwnPropertyNames(m);
        const states = [];
        const directions = [];

        this.headers = [];
        this.table = [];

        for (let type of types) {

            for (let state of Object.getOwnPropertyNames(m[type] ?? {})) {

                if (!states.includes(state)) {
                    states.push(state);
                }

                for (let direction of Object.getOwnPropertyNames(m[type][state] ?? {})) {

                    if (!directions.includes(direction)) {
                        directions.push(direction);
                    }
                }
            }

            this.headers.push('order-payment.paymentMatrix.' + type);
        }

        for (let state of states) {
            for (let direction of directions) {

                let found = false;

                for (let type of types) {
                    if (m[type].hasOwnProperty(state) && m[type][state].hasOwnProperty(direction)) {
                        found = true;
                    }
                }

                if (found) {
                    const row = ['order-payment.paymentMatrix.' + state + '.' + direction];

                    for (let type of types) {
                        if (m[type].hasOwnProperty(state) && m[type][state].hasOwnProperty(direction)) {
                            row.push({amount: m[type][state][direction], currency: this.order.currency});
                        } else {
                            row.push(false);
                        }
                    }

                    this.table.push(row);
                }
            }
        }
    }
}
