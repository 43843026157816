import {bindable, computedFrom, customElement, inject} from "aurelia-framework";
import {DateValueConverter} from "../../converters";
import {I18N} from "aurelia-i18n";

@customElement("sio-tourism-booking-state-display")
@inject(DateValueConverter, I18N)
export class BookingStateDisplay {
    @bindable state;
    @bindable stateBefore;
    @bindable item;

    constructor(dateConverter, i18n) {
        this.dateConverter = dateConverter;
        this.i18n = i18n;
    }

    @computedFrom("state", "stateBefore", "item")
    get title() {
        const title = [];

        if (this.stateBefore) {
            title.push(this.i18n.tr("order.detail.bookingStatus." + this.stateBefore) + " →");
        }

        title.push(this.i18n.tr("order.detail.bookingStatus." + this.state));

        let info = this.item.order?.bookingStatusReason;

        if ("option" === this.state || 'optionRequest' === this.state) {
            info = "bis " + this.dateConverter.toView(this.item.optionBookingRelease, 'L');

            info += ", " + this.i18n.tr("setting.order.option_realization_behavior." + this.item.order.optionRealizationBehavior);
        }

        if ("bookingOnExecution" === this.state) {
            info = this.i18n.tr("order.execution." + this.item.bookingExecutionType);
        }

        if ((info ?? "").length) {
            title.push("(" + info + ")");
        }

        return title.join(" ");
    }
}
