import {inject} from "aurelia-framework";
import {Executor} from "../executor";
import {FlashService} from "../../../../flash/flash-service";
import * as _ from "lodash";
import {ConditionMatcher} from "../../../../condition-builder/condition-matcher";
import {ModuleConfigClient} from "../../../../api/module-config-client";

@inject(
    FlashService,
    ConditionMatcher,
    ModuleConfigClient
)
export class DefaultExecutor extends Executor {
    flash;
    conditionMatcher;
    moduleConfigClient;

    constructor(
        flash,
        conditionMatcher,
        moduleConfigClient
    ) {
        super();

        this.flash = flash;
        this.conditionMatcher = conditionMatcher;
        this.moduleConfigClient = moduleConfigClient;
    }

    match(action, context) {
        return true;
    }

    execute(action, context) {
        const {
            allItemsChecked,
            selectedItems,
            items,
            selectedParticipants,
            participants,
            order
        } = context;

        if (_.isArray(action.workflowId)) {
            action.workflowId = allItemsChecked ? action.workflowId[0] : action.workflowId[1]; // update action??
        }

        return this.moduleConfigClient
            .getWorkflow(action.workflowId)
            .then(workflow => {
                const context = {
                    modelId: workflow.modelId,
                    confirm: action.confirm || workflow.confirm,
                    confirmTitle: action.confirmTitle || workflow.confirmTitle
                };
                let entityMap;

                switch (workflow.modelId) {

                    case 'order/order':
                        context.id = [order.id];
                        entityMap = new Map([[order.id, order]]);
                        break;

                    case 'order/order-item':
                        context.id = selectedItems;
                        entityMap = items;
                        break;

                    case 'tourism/journey-participant':
                        context.id = _.flatten(selectedParticipants);
                        entityMap = participants;
                        break;

                    default:
                        return Promise.reject('Unbekannter Objekt-Typ');
                }

                // for usability, we just filter selected items by workflow conditions here
                if (false !== workflow.bulk && workflow.hasOwnProperty('conditions')) {
                    context.id = context.id
                        .filter(id => this.conditionMatcher.matchConditions(entityMap.get(id), workflow.conditions));
                }

                switch (context.id.length) {
                    case 0:
                        return Promise.reject('Diese Operation ist mit der aktuellen Auswahl nicht möglich.');

                    case 1:
                        context.id = context.id[0];
                }

                return context;
            });
    }
}
