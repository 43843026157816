import * as _ from "lodash";

export class BucketsGenerator {
    buildBuckets(
        items,
        orderParticipants,
        withoutParticipant,
        journeys,
        defaultSortableOptions
    ) {
        // detect buckets
        let buckets = new Map();

        for (let item of items.values()) {

            //Important to clone here, so we keep original items
            item = _.cloneDeep(item);

            let bucket = item.mergeIdentifier;

            if (item.participants && item.participants.length > 0) {

                _.each(item.participants, (itemParticipant, index) => {
                    const participant = orderParticipants.get(itemParticipant.id);

                    if (!participant) { // Participant is possibly in separate order
                        return true;
                    }

                    itemParticipant.createdAt = participant.createdAt;
                    itemParticipant.objectLabel = participant.objectLabel;
                });

                _.each(item.participants, (itemParticipant, index) => {
                    const participant = orderParticipants.get(itemParticipant.id);

                    if (!participant) { // Participant is possibly in separate order
                        return true;
                    }

                    participant.bucket.push(bucket);
                    participant.items.push(item);
                });

            } else {
                item.id = [item.id];
                withoutParticipant.items.push(item);
            }
        }

        for (const participant of orderParticipants.values()) {
            const bucket = participant.bucket.join('\0');

            if (!buckets.has(bucket)) {
                buckets.set(bucket, [participant]);
            } else {
                buckets.get(bucket).push(participant);
            }
        }

        let num = 1;

        return Array.from(buckets.values()).sort((a, b) => b.length - a.length).map(participants => {

            participants[0].num = num++;

            let items = participants[0].items.map(item => {

                //Cast to array if it is not already one (might be one because item gets displayed in multiple buckets)
                item.id = _.castArray(item.id);

                return item;
            });

            for (let i = 1; i < participants.length; i++) {
                participants[i].num = num++;
                for (let j = 0; j < items.length; j++) {

                    if (items[j] !== participants[i].items[j]) {

                        if (items[j].id.indexOf(participants[i].items[j].id) > -1) {
                            continue;
                        }

                        items[j].id.push(participants[i].items[j].id);
                        items[j].amount += participants[i].items[j].amount;

                        if (items[j].itemPrice && participants[i].items[j].itemPrice) {
                            items[j].itemPrice.amount += participants[i].items[j].itemPrice.amount;
                        }

                        if (items[j].commission && participants[i].items[j].commission) {
                            items[j].commission.amount += participants[i].items[j].commission.amount;
                        }

                        for (let l = 0; l < participants[i].items[j].participants.length; l++) {
                            items[j].participants.push(participants[i].items[j].participants[l]);
                        }
                    }
                }
            }

            //Important that sorting happens after merging
            items = items.sort((a, b) => {

                if (a.sort == b.sort) {

                    return (a.fromDate || 'ZZZZZ').localeCompare(b.fromDate);
                }

                return a.sort - b.sort;
            }).map(item => {

                if (item.product && 'tourism/journey' === item.product.modelId) {
                    if (!journeys.has(item.product.id)) {
                        journeys.set(item.product.id, item.product);
                    }
                }

                return item;
            });

            return {
                collapsed: false,
                items: items,
                participants: participants.map(participant => {
                    delete participant.items;
                    delete participant.bucket;
                    return participant;
                }),
                sortableOptions: _.defaults(
                    {
                        group: {
                            name: 'bucket-' + num,
                            put: ['without-participant'],
                        }
                    },
                    defaultSortableOptions
                ),
            };
        });
    }
}
